import React, { ReactElement } from "react";
import { IConfirmationView } from "./types";

const ConfirmationView: React.FC<IConfirmationView> = ({
  segments,
  onPaste,
  handleConfirmation,
  username,
  handleChange,
}: IConfirmationView): ReactElement => {
  console.log("confirmation view");
  return (
    <section className="bg-white">
      <div className="flex flex-col items-center justify-center px-6 py-8 mx-auto md:h-screen lg:py-0">
        <a
          href="#"
          className="flex items-center mb-6 text-2xl font-semibold text-gray-900 dark:text-black"
        >
          Greenarc
        </a>
        <div className="w-full bg-white rounded-lg shadow dark:border md:mt-0 sm:max-w-md xl:p-0 dark:border-gray-700">
          <div className="p-6 space-y-4 md:space-y-6 sm:p-8">
            <h1 className="text-xl font-bold leading-tight tracking-tight text-gray-900 md:text-2xl dark:text-black">
              Verification
            </h1>
            <p className="text-l font-light leading-tight tracking-tight text-gray-600 md:text-l dark:text-grey">
              Enter the verification code that we sent to your email.
            </p>
            <form>
              <div>
                {segments.map((segment: string, index: number) => {
                  return (
                    <input
                      key={`input-${index}`}
                      className="m-2 border h-10 w-10 text-center form-control rounded"
                      type="text"
                      id={index.toString()}
                      maxLength={1}
                      value={segment}
                      onPaste={onPaste}
                      onChange={(e) => handleChange(e.target.value, index)}
                    />
                  );
                })}
              </div>
              <button
                // type="submit"
                onClick={handleConfirmation}
                className="w-full text-black border-black bg-primary-600 hover:bg-primary-700 focus:ring-4 focus:outline-none focus:ring-primary-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-primary-600 dark:hover:bg-primary-700 dark:focus:ring-primary-800"
              >
                Submit
              </button>
            </form>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ConfirmationView;
