import { FC, ReactElement } from "react";
import IDashboardChargerCard from "./types";

const DashboardChargerCard: FC<IDashboardChargerCard> = ({
  navigate,
  state,
}: IDashboardChargerCard): ReactElement => {
  console.log("dashbaord card state", state);
  console.log("window.location.origin", window.location.origin);
  return state?.singleSite?.circuits?.map((circuit: any) => (
    <tr>
      <td
        className="whitespace-nowrap py-5 pl-4 pr-3 text-sm sm:pl-0"
        onClick={() => navigate(`/charger/${circuit.chargers[0].id}`)}
        style={{ cursor: "pointer" }}
      >
        <div className="flex items-center">
          <div className="h-11 w-11 flex-shrink-0">
            <img
              className="h-11 w-11 rounded-full"
              src="/easee-charger-images.png"
              alt="easee-charger"
            />
          </div>
          <div className="ml-4">
            <div className="font-medium text-gray-900">
              {circuit.chargers[0].id} (Easee Charge)
            </div>
          </div>
        </div>
      </td>
      <td
        className="whitespace-nowrap px-3 py-5 text-sm text-gray-500"
        onClick={() => navigate(`/site/630005`)}
        style={{ cursor: "pointer" }}
      >
        <div className="font-medium text-gray-900">
          <a href="/site">Padiham</a>
        </div>
      </td>
      <td className="whitespace-nowrap px-3 py-5 text-sm text-gray-500">
        <span className="inline-flex items-center rounded-md bg-green-50 px-2 py-1 text-xs font-medium text-green-700 ring-1 ring-inset ring-green-600/20">
          Online
        </span>
      </td>
    </tr>
  ));
};

export default DashboardChargerCard;
