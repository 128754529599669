import { generateClient } from "aws-amplify/api";
import { GraphQLResult } from "@aws-amplify/api";

const fetcher = async <T>(query: string): Promise<GraphQLResult<T>> => {
  const client = generateClient();

  return (await client.graphql({
    query: query,
  })) as GraphQLResult<T>;
};

export default fetcher;
