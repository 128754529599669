import React, { ReactElement } from "react";
import { ILoginInputView } from "./types";

const LoginInputView: React.FC<ILoginInputView> = ({
  handleChange,
  value,
  label,
  id,
  placeholder,
  type,
}: ILoginInputView): ReactElement => {
  return (
    <div>
      <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
        {label}
      </label>
      <input
        type={type}
        name={id}
        id={id}
        value={value}
        placeholder={placeholder}
        onChange={(e) => handleChange(e.target.value, id)}
        className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-white-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-black dark:focus:green-500 dark:focus:border-green-500"
      />
    </div>
  );
};

export default LoginInputView;
