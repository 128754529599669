import "./App.css";
import { Routes, Route } from "react-router-dom";
import { LoginPage, ChargerPage, SitePage, DashboardPage } from "./pages";
import Layout from "./Layouts/LayoutContainer";

function App() {
  return (
    <div className="App">
      <Routes>
        <Route path="/user_login" element={<LoginPage />} />
        <Route path="/home" element={<Layout page={<DashboardPage />} />} />
        <Route
          path="/charger/:id"
          element={<Layout page={<ChargerPage />} />}
        />
        <Route path="/site/:id" element={<Layout page={<SitePage />} />} />
      </Routes>
    </div>
  );
}

export default App;
