import { signUp } from "aws-amplify/auth";

type SignUpParameters = {
  username: string;
  password: string;
  email: string;
};

async function handleSignUp({ username, password, email }: SignUpParameters) {
  let nextStepper
  try {
    const { isSignUpComplete, userId, nextStep } = await signUp({
      username,
      password,
      options: {
        userAttributes: {
          email, // E.164 number convention
        },
        // optional
        autoSignIn: true, // or SignInOptions e.g { authFlowType: "USER_SRP_AUTH" }
      },
    });
    nextStepper = nextStep
  } catch (error) {
    console.log("error signing up:", error);
  }
  if(nextStepper) return nextStepper
}

export default handleSignUp;
