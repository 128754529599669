import { FC, ReactElement } from "react";
import { useNavigate } from "react-router-dom";
import { signOut } from "aws-amplify/auth";
import LayoutView from "./LayoutView";

interface ILayout {
  page: ReactElement;
}

const Layout: FC<ILayout> = ({ page }: ILayout): ReactElement => {
  const navigate = useNavigate();

  async function handleSignOut() {
    try {
      console.log("signout");
      const response = await signOut();
      console.log("response", response);
      navigate("/user_login");
    } catch (error) {
      console.log("error signing out: ", error);
    }
  }

  return <LayoutView page={page} handleSignOut={handleSignOut} />;
};

export default Layout;

