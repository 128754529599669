import { signIn, SignInInput } from 'aws-amplify/auth';

async function handleSignIn({ username, password }: SignInInput) {
  
  try {
    const { isSignedIn, nextStep } = await signIn({ username, password });
    console.log('isSignedIn',isSignedIn)
    
    return nextStep

  } catch (error) {
    console.log("error signing in", error);
  }
}

export default handleSignIn;
