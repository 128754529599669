import { FC, ReactElement, useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { AreaChartHero } from "../../components/Charts";
import { fetcher } from "../../helpers/index";
import { chargerUsageMonth, chargerUsageDay } from "../../graphQL";
import { IChargerUsage } from "./types";
import SwitcherButton from "../../components/Buttons";

// sessions/charger/ECLXY69W/daily
// sessions/charger/ECLXY69W/monthly

const ChargerPage: FC = (): ReactElement => {
  const { id } = useParams();
  const [state, setState] = useState<
    | { chargerUsageMonth: IChargerUsage[] }
    | { chargerUsageDay: IChargerUsage[] }
    | undefined
  >();
  const [range, setRange] = useState<string>("monthly");
  const [loading, setLoading] = useState<boolean>(true);
  console.log("id", id);
  useEffect(() => {
    const getChargerUsageMonth = async (): Promise<void> => {
      const arg =
        range === "monthly" ? chargerUsageMonth(id) : chargerUsageDay(id);
      const typeArg = range.split("")[0].toUpperCase() + range.slice(1, -2);
      setLoading(true);
      const response = await fetcher<{
        [`chargerUsageMonth`]: IChargerUsage[];
      }>(arg);
      const data = response && response.data;

      setState(data);
      setLoading(false);
    };

    const getChargerUsageDay = async (): Promise<void> => {
      const arg =
        range === "monthly" ? chargerUsageMonth(id) : chargerUsageDay(id);
      const typeArg = range.split("")[0].toUpperCase() + range.slice(1, -2);
      const response = await fetcher<{
        [`chargerUsageDay`]: IChargerUsage[];
      }>(arg);
      const data = response && response.data;

      setState(data);
      setLoading(false);
    };
    if (range === "monthly") {
      getChargerUsageMonth();
    } else if (range === "daily") {
      getChargerUsageDay();
    }
  }, [range]);

  console.log("range", range);
  console.log("state", state);

  return (
    <main>
      <div>
        <br />
        <div className="mt-5 grid grid-cols-1 gap-5 sm:grid-cols-2">
          <h2 className="header_greenarc font-semibold leading-7 text-gray-900">
            Device Overview
          </h2>
        </div>
        <div className="flex justify-between">
          <div className="sm:flex" style={{ marginTop: "30px" }}>
            <div className="mb-4 flex-shrink-0 sm:mb-0 sm:mr-4">
              <img
                className="mx-auto h-32 w-32 flex-shrink-0 rounded-full"
                src="https://www.smarthomecharge.co.uk/site/assets/files/7421/easee-one-hero-5.1000x1100.q75.jpg"
                alt=""
              />
            </div>
            <div>
              <p className=" black mt-1">Serial Number - {id}</p>
              <p className=" black mt-1">PIN Code - 1199</p>
              <button
                type="button"
                className="rounded-full bg-white px-3.5 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
              >
                Reboot
              </button>
            </div>
          </div>
          <SwitcherButton value={range} setValue={setRange} />
        </div>

        <div style={{ marginTop: "30px" }}>
          <br />
          <h2 className="header_greenarc font-semibold leading-7 text-gray-900">
            Energy Usage & Cost
          </h2>
          {<AreaChartHero data={state} loading={loading} range={range} />}
        </div>
      </div>
    </main>
  );
};

export default ChargerPage;
