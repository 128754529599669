const chargerUsageMonth = (id: string | undefined) => `query ChargerUsageMonth {
    chargerUsageMonth(id: "${id}") {
        year
        month
        totalEnergyUsage
        totalCost
        currencyId
        authTokensUsage
    }
}`;

export default chargerUsageMonth;
