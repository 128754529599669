import React, { useState } from "react";

const SwitcherButton = ({ value, setValue }: any) => {
  const [isChecked, setIsChecked] = useState(false);

  const handleCheckboxChange = (e: any) => {
    console.log("e", e.target.value);
    const result = "monthly";
    setValue(result);
    setIsChecked(!isChecked);
  };

  return (
    <>
      <label
        className="themeSwitcherTwo shadow-card relative inline-flex cursor-pointer select-none items-center justify-center rounded-md bg-white p-1"
        style={{ paddingRight: "24px" }}
      >
        <input
          type="checkbox"
          className="sr-only"
          checked={isChecked}
          value={value}
          onChange={handleCheckboxChange}
        />
        <span
          style={{ height: "36px" }}
          className={`flex items-center space-x-[6px] rounded py-2 px-[18px] text-sm font-medium ${
            !isChecked ? "text-primary bg-[#f4f7ff]" : "text-body-color"
          }`}
        >
          Monthly
        </span>
        <span
          style={{ height: "36px" }}
          className={`flex items-center space-x-[6px] rounded py-2 px-[18px] text-sm font-medium ${
            isChecked ? "text-primary bg-[#f4f7ff]" : "text-body-color"
          }`}
        >
          Daily
        </span>
      </label>
    </>
  );
};

export default SwitcherButton;
