import { FC, ReactElement } from "react";
import { AreaChart } from "@tremor/react";
import { IChargerUsage } from "../../pages/ChargerPage/types";
import { chargerUsageMonth } from "../../graphQL";

interface ILookup {
  [key: string]: string;
}

const monthLookUp: ILookup = {
  1: "Jan",
  2: "Feb",
  3: "Mar",
  4: "Apr",
  5: "May",
  6: "Jun",
  7: "Jul",
  8: "Aug",
  9: "Sep",
  10: "Oct",
  11: "Nov",
  12: "Dec",
};
const customTooltip = (props: any) => {
  const { payload, active } = props;
  if (!active || !payload) return null;
  return (
    <div className="w-56 rounded-tremor-default border border-tremor-border bg-tremor-background p-2 text-tremor-default shadow-tremor-dropdown">
      {payload.map((category: any, idx: number) => (
        <div key={idx} className="flex flex-1 space-x-2.5">
          <div
            className={`flex w-1 flex-col bg-${category.color}-500 rounded`}
          />
          <div className="space-y-1">
            <p className="text-tremor-content">{category.dataKey}</p>
            <p className="font-medium text-tremor-content-emphasis">
              {category.value}
            </p>
          </div>
        </div>
      ))}
    </div>
  );
};

const dataFormatter = (number: number) =>
  `$${Intl.NumberFormat("us").format(number).toString()}`;

const currencyFormatter = (number: number) =>
  `${Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "GBP",
  })
    .format(number)
    .toString()}`;

const AreaChartHero: FC<{
  data: { chargerUsageMonth: IChargerUsage[] } | any;
  range: string;
  loading: boolean;
}> = ({
  data,
  range,
  loading,
}: {
  data: { chargerUsageMonth: IChargerUsage[] } | any;
  range: string;
  loading: boolean;
}): ReactElement => {
  const dataaa =
    range === "monthly" ? data?.chargerUsageMonth : data?.chargerUsageDay;
  const chartData =
    !loading &&
    dataaa.map((ele: any) => ({
      Cost: ele.totalCost,
      Energy: ele.totalEnergyUsage,
      date: `${monthLookUp[ele.month.toString()]} ${ele.year.toString().slice(-2)}`,
    }));

  return (
    <AreaChart
      className="h-80"
      data={chartData}
      index="date"
      categories={["Cost", "Energy"]}
      colors={["green", "yellow"]}
      valueFormatter={currencyFormatter}
      yAxisWidth={60}
      onValueChange={(v) => console.log(v)}
    />
  );
};

export default AreaChartHero;
