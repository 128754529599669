import { FC, ReactElement, useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { fetcher } from "../../helpers/index";
import { singleSite } from "../../graphQL";
import DashboardChargerCards from "../../components/Cards/DashBoardChargerCard";
import { ISingleSite } from "./types";

const DashboardPage: FC = (): ReactElement => {
  const [state, setState] = useState<ISingleSite | undefined>();

  const navigate = useNavigate();

  useEffect(() => {
    const getSite = async (): Promise<void> => {
      const response = await fetcher<ISingleSite>(singleSite);
      const data = response && response.data;

      setState(data);
    };

    getSite();
  }, []);

  return (
    <div className="h-screen flex justify-center items-center">
      <main>
        <div>
          <br />
          <h2 className="header_greenarc font-semibold leading-7 text-gray-900">
            Dashboard
          </h2>
          <dl className="mt-5 grid grid-cols-1 gap-5 sm:grid-cols-4">
            <div className="overflow-hidden rounded-lg bg-white px-4 py-5 shadow sm:p-6">
              <dt className="truncate text-sm font-medium text-gray-500">
                Total Devices
              </dt>
              <dd className="mt-1 text-3xl font-semibold tracking-tight text-gray-900">
                5
              </dd>
            </div>
            <div className="overflow-hidden rounded-lg bg-white px-4 py-5 shadow sm:p-6">
              <dt className="truncate text-sm font-medium text-gray-500">
                Healthy Assets
              </dt>
              <dd
                className="mt-1 text-3xl font-semibold tracking-tight text-gray-900"
                style={{ color: "#43B649" }}
              >
                100%
              </dd>
            </div>
            <div className="overflow-hidden rounded-lg bg-white px-4 py-5 shadow sm:p-6">
              <dt className="truncate text-sm font-medium text-gray-500">
                Asset Errors
              </dt>
              <dd
                className="mt-1 text-3xl font-semibold tracking-tight text-gray-900"
                style={{ color: "#ED1C24" }}
              >
                0
              </dd>
            </div>
            <div className="overflow-hidden rounded-lg bg-white px-4 py-5 shadow sm:p-6">
              <dt className="truncate text-sm font-medium text-gray-500">
                Number of Sites
              </dt>
              <dd
                className="mt-1 text-3xl font-semibold tracking-tight text-gray-900"
                style={{ color: "#149B9E" }}
              >
                1
              </dd>
            </div>
          </dl>
        </div>
        <div>
          <div className="mt-5 grid grid-cols-1 gap-5 sm:grid-cols-2">
            <div className="px-4 sm:px-6 lg:px-8">
              <div className="sm:items-center">
                <div className="mt-8 flow-root">
                  <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                    <div style={{ maxWidth: "200px" }}>
                      <label
                        htmlFor="search"
                        className="block text-sm font-medium leading-6 text-gray-900"
                      >
                        Quick search
                      </label>
                      <div className="relative mt-2 flex items-center">
                        <input
                          type="text"
                          name="search"
                          id="search"
                          className="block w-full rounded-md border-0 py-1.5 pr-14 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                        />
                        <div className="absolute inset-y-0 right-0 flex py-1.5 pr-1.5">
                          <kbd className="inline-flex items-center rounded border border-gray-200 px-1 font-sans text-xs text-gray-400">
                            ⌘K
                          </kbd>
                        </div>
                      </div>
                    </div>

                    <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
                      <table className="min-w-full divide-y divide-gray-300">
                        <thead>
                          <tr>
                            <th
                              scope="col"
                              className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-0"
                            >
                              Name
                            </th>
                            <th
                              scope="col"
                              className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-0"
                            >
                              Site
                            </th>
                            <th
                              scope="col"
                              className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                            >
                              Status
                            </th>
                          </tr>
                        </thead>
                        <tbody className="divide-y divide-gray-200 bg-white">
                          <DashboardChargerCards
                            state={state}
                            navigate={navigate}
                          />
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div style={{ color: "black", marginTop: "30px" }}>
              <div className="relative w-full h-96">
                <iframe
                  src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2356.331021094331!2d-2.30883425!3d53.80139325!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x48746ce4aa827193%3A0x95c3897166d8b92a!2sLCM%20Environmental!5e0!3m2!1sen!2suk!4v1714031943755!5m2!1sen!2suk"
                  width="600"
                  height="450"
                  style={{ border: 0 }}
                  loading="lazy"
                  referrerPolicy="no-referrer-when-downgrade"
                ></iframe>
              </div>
            </div>
          </div>
        </div>
      </main>
    </div>
  );
};

export default DashboardPage;
