const chargerUsageDay = (id: string | undefined) => `query ChargerUsageDay {
    chargerUsageDay(id: "${id}")  {
        year
        month
        dayOfMonth
        totalEnergyUsage
        totalCost
        currencyId
        authTokensUsage
    }
}`;

export default chargerUsageDay;
