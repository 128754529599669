const singleSite = `query SingleSite {
    singleSite {
        uuid
        id
        siteKey
        name
        levelOfAccess
        address {
            street
            buildingNumber
            zip
            area
            country {
                id
                name
                phonePrefix
            },
            latitude
            longitude
            altitude
        },
        contactInfo {
            installerName
            installerPhoneNumber
            ownerName
            ownerPhoneNumber
            company
        },
        costPerKWh
        costPerKwhExcludeVat
        currencyId
        siteType
        ratedCurrent
        vat
        partnerId
        installerId
        installerUserId
        supportId
        installerAlias
        useDynamicMaster
        circuits {
                id
                uuid
                siteId
                circuitPanelId
                panelName
                ratedCurrent
                chargers {
                        id
                        name
                        color
                        createdOn
                        updatedOn
                        backPlate {
                            id
                            masterBackPlateId
                            name
                            features
                        },
                        levelOfAccess
                        productCode
                        userRole
                        isTemporary
                    },
               
        }
    }
}`;

export default singleSite;
