import { confirmSignUp, type ConfirmSignUpInput } from "aws-amplify/auth";

async function handleSignUpConfirmation({
  username,
  confirmationCode,
}: ConfirmSignUpInput, navigate: Function) {
  try {
    const { isSignUpComplete } = await confirmSignUp({
      username,
      confirmationCode,
    });
    if(isSignUpComplete) {
      navigate('/home')
    }
  } catch (error) {
    console.log("error confirming sign up", error);
  }
}

export default handleSignUpConfirmation;
