import { FC, ReactElement } from "react";

interface IChargerCard {
  title: String;
  body: String;
  status: String;
  url?: String;
}

const ChargerCard: FC<IChargerCard> = ({
  title,
  body,
  status,
  url,
}: IChargerCard): ReactElement => {
  return (
    <li className="col-span-1 flex flex-col divide-y divide-gray-200 rounded-lg bg-white text-center shadow inactive">
      <div className="flex flex-1 flex-col p-8">
        <img
          className="mx-auto h-32 w-32 flex-shrink-0 rounded-full"
          src="https://www.smarthomecharge.co.uk/site/assets/files/7421/easee-one-hero-5.1000x1100.q75.jpg"
          alt=""
        />
        <h3 className="mt-6 text-sm font-medium text-gray-900">{title}</h3>
        <dl className="mt-1 flex flex-grow flex-col justify-between">
          <dt className="sr-only">Title</dt>
          <dd className="text-sm text-gray-500">{body}</dd>
          <dt className="sr-only">Role</dt>
          <dd className="mt-3">
            <span className="inline-flex items-center rounded-full bg-red-50 px-2 py-1 text-xs font-medium text-red-700 ring-1 ring-inset ring-red-600/20">
              {status}
            </span>
          </dd>
        </dl>
      </div>
      <div>
        <div className="-mt-px flex divide-x divide-gray-200">
          <div className="flex w-0 flex-1">
            <a
              href="mailto:janecooper@example.com"
              className="relative -mr-px inline-flex w-0 flex-1 items-center justify-center gap-x-3 rounded-bl-lg border border-transparent py-4 text-sm font-semibold text-gray-900"
            >
              Details
            </a>
          </div>
        </div>
      </div>
    </li>
  );
};

export default ChargerCard;
