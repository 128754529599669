import { FC, ReactElement, useEffect, useState } from "react";
import { AreaChartHero } from "../../components/Charts";
import { AlertCard, ChargerCard } from "../../components";
import { useParams } from "react-router-dom";
import { fetcher } from "../../helpers/index";
import { chargerUsageMonth } from "../../graphQL";
import { IChargerUsage } from "../ChargerPage/types";

const SitePage: FC = (): ReactElement => {
  // const { id } = useParams();
  // const [state, setState] = useState<IChargerUsage[] | undefined>();
  // console.log("id", id);
  // useEffect(() => {
  //   const getChargerUsage = async (): Promise<void> => {
  //     const response = await fetcher<IChargerUsage[]>(chargerUsageMonth(id));
  //     const data = response && response.data;

  //     setState(data);
  //   };

  //   getChargerUsage();
  // }, []);
  return (
    <main>
      <div>
        <div className="mt-5 grid grid-cols-1 gap-5 sm:grid-cols-2">
          <h2 className="header_greenarc font-semibold leading-7 text-gray-900">
            Overview - LCM
          </h2>
          <div className="relative inline-block text-left">
            <div>
              <button
                type="button"
                className="inline-flex w-full justify-center gap-x-1.5 rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
                id="menu-button"
                aria-expanded="true"
                aria-haspopup="true"
              >
                Week
                <svg
                  className="-mr-1 h-5 w-5 text-gray-400"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                  aria-hidden="true"
                >
                  <path
                    fill-rule="evenodd"
                    d="M5.23 7.21a.75.75 0 011.06.02L10 11.168l3.71-3.938a.75.75 0 111.08 1.04l-4.25 4.5a.75.75 0 01-1.08 0l-4.25-4.5a.75.75 0 01.02-1.06z"
                    clip-rule="evenodd"
                  />
                </svg>
              </button>
            </div>

            {/* <!--
                Dropdown menu, show/hide based on menu state.
            
                Entering: "transition ease-out duration-100"
                  From: "transform opacity-0 scale-95"
                  To: "transform opacity-100 scale-100"
                Leaving: "transition ease-in duration-75"
                  From: "transform opacity-100 scale-100"
                  To: "transform opacity-0 scale-95"
              --> */}
            {/* <div className="absolute right-0 z-10 mt-2 w-56 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none" style="display: hide;" ="menu" aria-orientation="vertical" aria-labelledby="menu-button" tabindex="-1">
                
              </div> */}
          </div>
        </div>

        <dl className="mt-5 grid grid-cols-1 gap-5 sm:grid-cols-4">
          <div className="overflow-hidden rounded-lg bg-white px-4 py-5 shadow sm:p-6">
            <dt className="truncate text-sm font-medium text-gray-500">
              Usage
            </dt>
            <dd
              className="mt-1 text-3xl font-semibold tracking-tight text-gray-900"
              style={{ color: "#149B9E" }}
            >
              348 kWh
            </dd>
          </div>
          <div className="overflow-hidden rounded-lg bg-white px-4 py-5 shadow sm:p-6">
            <dt className="truncate text-sm font-medium text-gray-500">Cost</dt>
            <dd className="mt-1 text-3xl font-semibold tracking-tight text-gray-900">
              £ 1340
            </dd>
          </div>
          <div className="overflow-hidden rounded-lg bg-white px-4 py-5 shadow sm:p-6">
            <dt className="truncate text-sm font-medium text-gray-500">
              Healthy Assets
            </dt>
            <dd
              className="mt-1 text-3xl font-semibold tracking-tight text-gray-900"
              style={{ color: "#43B649" }}
            >
              99%
            </dd>
          </div>
          <div className="overflow-hidden rounded-lg bg-white px-4 py-5 shadow sm:p-6">
            <dt className="truncate text-sm font-medium text-gray-500">
              Asset Errors
            </dt>
            <dd
              className="mt-1 text-3xl font-semibold tracking-tight text-gray-900"
              style={{ color: "#ED1C24" }}
            >
              1
            </dd>
          </div>
        </dl>

        <div className="mt-5 grid grid-cols-2 gap-5 sm:grid-cols-3">
          <div className="col-span-2">
            {/* {state && <AreaChartHero data={state} />} */}
          </div>

          <div className="relative w-full h-75 ">
            <iframe
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2356.331021094331!2d-2.30883425!3d53.80139325!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x48746ce4aa827193%3A0x95c3897166d8b92a!2sLCM%20Environmental!5e0!3m2!1sen!2suk!4v1714031943755!5m2!1sen!2suk"
              width="600"
              height="450"
              style={{ border: 0 }}
              loading="lazy"
              referrerPolicy="no-referrer-when-downgrade"
            ></iframe>
          </div>
        </div>
      </div>
      <div>
        <div>
          <h2
            className="header_greenarc font-semibold leading-7 text-gray-900"
            style={{ marginTop: "40px" }}
          >
            Electric Chargepoints
          </h2>
          <br />
          <AlertCard
            error="The is a problem with Easee charger - ECLXY69W"
            desc="Charger needs a reboot"
          />
          <br />
          <ul role="list" className="link-card-grid">
            <ChargerCard
              title="ECP2DFRL (Easee Charge)"
              body="No car connected"
              status="Online"
              url="/detail"
            />
            <ChargerCard
              title="EC3AVCMS  (Easee Charge)"
              body="No car connected"
              status="Online"
              url="/detail"
            />
            <ChargerCard
              title="ECLXY69W (Easee Charge)"
              body="Online"
              status="Inactive"
            />
            <ChargerCard
              title="ECJXLGU3 (Easee Charge)"
              body="No car connected"
              status="Online"
              url="/detail"
            />
            <br />
          </ul>
        </div>
      </div>
    </main>
  );
};

export default SitePage;
